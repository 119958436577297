import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { ContainerIcon, TruckIcon, CarIcon } from "lucide-react";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";

// ---- Panel Component -------------------------------------
export default function TrackingPanel({
  data,
  selectedAsset,
  setSelectedAsset,
}) {
  // initialize list of truck cards
  const [listedAssets, setListedAssets] = useState([]);

  // initialize state of search bar
  const [inputValue, setInputValue] = useState("");

  // initialize number of records
  const [lenRecords, setLenRecords] = useState(listedAssets.length);

  // update state when input changes
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // use effect to refresh list when search input changes
  useEffect(() => {
    const results = searchTrucksByInput(data, inputValue, 1000);
    setListedAssets(results);
    setLenRecords(results.length);
  }, [data, inputValue]); // adding data ensures this runs on mount too

  return (
    <div className="h-full flex flex-col p-2 overflow-y-auto">
      {selectedAsset && (
        <input
          className="mt-4 flex h-10 text-sm w-full rounded-lg border border-input bg-background px-3 py-2 text-lg placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline-none"
          type="text"
          placeholder="Search for a vehicle..."
          disabled
        />
      )}

      {!selectedAsset && (
        <input
          className="mt-4 flex h-10 text-sm w-full px-4 rounded-lg border border-input bg-background px-3 py-3 text-lg placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline-none"
          type="text"
          placeholder="Search for a vehicle..."
          value={inputValue}
          onChange={handleInputChange}
        />
      )}

      <Separator className="mt-4" />

      {selectedAsset &&
        (selectedAsset.source === "vehicle" ? (
          <SelectedVehicleCard vehicle={selectedAsset} />
        ) : (
          <SelectedContainerCard container={selectedAsset} />
        ))}

      {selectedAsset == null &&
        listedAssets.map((asset, n) =>
          asset.source === "vehicle" ? (
            <VehicleCard
              key={`truck-card-${n}`}
              vehicle={asset}
              setSelectedAsset={setSelectedAsset}
            />
          ) : (
            <ContainerCard
              key={`container-card-${n}`}
              container={asset}
              setSelectedAsset={setSelectedAsset}
            />
          )
        )}

      {!selectedAsset && (
        <div className="pt-4 flex justify-end mt-auto justify-center">
          <div className="text-gray-500 text-sm font-semibold">
            Showing {lenRecords} Assets
          </div>
        </div>
      )}
      {selectedAsset && (
        <div className="flex justify-end mt-auto">
          <Button
            className="w-full"
            onClick={() => {
              setSelectedAsset(null);
            }}
          >
            Reset Map
          </Button>
        </div>
      )}
    </div>
  );
}

// ---- Function to Search Through Trucks Array -------------------------------------
function searchTrucksByInput(trucksArray, inputString, n) {
  // Check if the search string is empty and return the first n trucks
  if (inputString.trim() === "") {
    return trucksArray.slice(0, n);
  }

  // Strip the input string of whitespace once
  const strippedInputString = inputString.replace(/\s+/g, "").toLowerCase();

  // Filter the trucks array and return only the first n results
  return trucksArray
    .filter((truckObj) => {
      // Ensure truck property exists and strip its whitespace before comparison
      return (
        truckObj.registration_number &&
        truckObj.registration_number
          .replace(/\s+/g, "")
          .toLowerCase()
          .includes(strippedInputString)
      );
    })
    .slice(0, n);
}

// ---- Card for each truck in control panel ----------------------------------
const vehicleLabelMapping = {
  car: "Car",
  truck: "Truck",
};

const vehicleIconMapping = {
  car: <CarIcon color="rgb(239 68 68)" />,
  truck: <TruckIcon color="rgb(37 99 235)" />,
};

function VehicleCard({ vehicle, setSelectedAsset }) {
  return (
    <div
      className="bg-slate-50 border mt-4 py-2 px-4 flex rounded-lg hover:bg-slate-100 cursor-pointer"
      onClick={() => {
        setSelectedAsset(vehicle);
      }}
    >
      <div className="flex flex-col justify-center p-4">
        {vehicleIconMapping[vehicle.vehicle_type]}
      </div>
      <div>
        <div className="font-semibold">{vehicle.registration_number}</div>
        <div className="text-md">
          {vehicle.year} {vehicle.manufacturer} {vehicle.vehicle_model}
        </div>

        <div className="font-light text-sm mt-1">{vehicle.time_diff}</div>
      </div>
      <div className="flex flex-col ml-auto justify-center">
        <div className="text-sm">
          Fuel Level: {formatNumberWithCommas(vehicle.fuel_level)} L
        </div>
        <div className="text-sm">Speed: {vehicle.speed} km/h</div>
      </div>
    </div>
  );
}

function SelectedVehicleCard({ vehicle }) {
  return (
    <div className="bg-blue-100 border mt-4 py-2 px-4 flex rounded-lg cursor-pointer">
      <div className="flex flex-col justify-center p-4">
        {vehicleIconMapping[vehicle.vehicle_type]}
      </div>
      <div>
        <div className="font-semibold">{vehicle.registration_number}</div>
        <div className="text-md">
          {vehicle.year} {vehicle.manufacturer} {vehicle.vehicle_model}
        </div>

        <div className="font-light text-sm mt-1">{vehicle.time_diff}</div>
      </div>
      <div className="flex flex-col ml-auto justify-center">
        <div className="text-sm">
          Fuel Level: {formatNumberWithCommas(vehicle.fuel_level)} L
        </div>
        <div className="text-sm">Speed: {vehicle.speed} km/h</div>
      </div>
    </div>
  );
}

// ---- Card for each Container in control panel ----------------------------------
function ContainerCard({ container, setSelectedAsset }) {
  return (
    <div
      className="bg-slate-50 border mt-4 py-2 px-4 flex rounded-lg hover:bg-slate-100 cursor-pointer"
      onClick={() => {
        setSelectedAsset(container);
      }}
    >
      <div className="flex flex-col justify-center p-4">
        <ContainerIcon color="rgb(22 163 74)" />
      </div>
      <div>
        <div className="font-semibold">{container.container_number}</div>
        <div className="text-sm">Container</div>
        <div className="font-light text-sm">{container.time_diff}</div>
      </div>
      <div className="flex flex-col ml-auto justify-center">
        <div className="text-sm">
          Battery:{" "}
          {formatNumberWithCommas((container.battery_voltage / 7200) * 100)} %
        </div>
        <div className="text-sm">Speed: {container.speed} km/h</div>
      </div>
    </div>
  );
}

function SelectedContainerCard({ container }) {
  return (
    <div className="bg-blue-100 border mt-4 py-2 px-4 flex rounded-lg cursor-pointer">
      <div className="flex flex-col justify-center p-4">
        <ContainerIcon color="rgb(22 163 74)" />
      </div>
      <div>
        <div className="font-semibold">{container.container_number}</div>
        <div className="text-sm">Container</div>
        <div className="font-light text-sm">{container.time_diff}</div>
      </div>
      <div className="flex flex-col ml-auto justify-center">
        <div className="text-sm">
          Battery:{" "}
          {formatNumberWithCommas((container.battery_voltage / 7200) * 100)} %
        </div>
        <div className="text-sm">Speed: {container.speed} km/h</div>
      </div>
    </div>
  );
}
