import { Navigate } from "react-router-dom";
// pages
import Login from "@/pages/login";
import NotFound from "@/pages/404";
import InvoicesDashboard from "@/pages/dashboard/invoices";
import FuelDashboard from "@/pages/dashboard/fuel";
import PartsDashboard from "@/pages/dashboard/parts";
import Trips from "@/pages/dispatch/trips";
import Tracking from "@/pages/dispatch/tracking";
import Live from "@/pages/dispatch/live";
import Vehicle from "@/pages/fleet/vehicle";
import RouteHistory from "@/pages/dispatch/routehistory";

// routes
const routes = [
  {
    path: "/",
    element: () => <Navigate to="/dispatch/tracking" replace={true} />,
    isPrivate: true,
    routeType: "location",
  },

  {
    path: "/dispatch",
    element: () => <Navigate to="/dispatch/tracking" replace={true} />,
    isPrivate: true,
    routeType: "location",
  },

  {
    path: "/dispatch/tracking",
    element: Tracking,
    isPrivate: true,
    routeType: "location",
  },
  {
    path: "/dispatch/route-history",
    element: RouteHistory,
    isPrivate: true,
    routeType: "location",
  },
  {
    path: "/dispatch/trips",
    element: Trips,
    isPrivate: true,
    routeType: "location",
  },
  {
    path: "/dispatch/live",
    element: Live,
    isPrivate: true,
    routeType: "location",
  },

  //{ path: "/fleet", element: Fleet, isPrivate: true },
  {
    path: "/fleet",
    element: () => <Navigate to="/fleet/2" replace={true} />,
    isPrivate: true,
    routeType: "private",
  },
  {
    path: "/fleet/:vehicleId",
    element: Vehicle,
    isPrivate: true,
    routeType: "private",
  },

  {
    path: "/dashboard",
    element: () => <Navigate to="/dashboard/invoices" replace={true} />,
    isPrivate: true,
    routeType: "dashboard",
  },
  {
    path: "/dashboard/invoices",
    element: InvoicesDashboard,
    isPrivate: true,
    routeType: "dashboard",
  },
  {
    path: "/dashboard/fuel",
    element: FuelDashboard,
    isPrivate: true,
    routeType: "dashboard",
  },
  {
    path: "/dashboard/parts",
    element: PartsDashboard,
    isPrivate: true,
    routeType: "dashboard",
  },

  { path: "/login", element: Login, isPrivate: false, routeType: "standard" },
  { path: "*", element: NotFound, isPrivate: false },
];

export default routes;
