import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import useSMSImmobilize from "@/hooks/useSMSImmobilize";

export default function ImmobilizeDialog({ selectedVehicle }) {
  // State to control dialog visibility
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { submitSMSImmobilize: submitSMSImmobilize } = useSMSImmobilize();

  // Close dialog function
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // Function to update the input value state
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Check if the input value is 'immobilize'
  const isInputCorrect = inputValue.toLowerCase() === "immobilize";

  return (
    <Dialog isOpen={isDialogOpen} onDismiss={closeDialog}>
      <DialogTrigger asChild>
        <Button
          className="w-full"
          variant="destructive"
          onClick={() => setIsDialogOpen(true)}
        >
          Immobilize Vehicle
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>WARNING: VEHICLE IMMOBILIZATION INITIATION</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col space-y-8">
          <div>
            You are about to immobilize a vehicle. This action will remotely
            disable the vehicle's engine, preventing it from being driven.
          </div>
          <div>
            This is a significant action that can have serious safety and
            operational implications, including potential legal consequences.
          </div>
          <div>Please consider the following before proceeding:</div>
          <ul class="list-disc px-8 space-y-4">
            <li>
              Ensure that immobilizing the vehicle is necessary and justified
              under your company's policies.
            </li>
            <li>
              Be aware that immobilizing the vehicle may put its occupants in
              potentially unsafe conditions.
            </li>
            <li>
              Understand that once immobilized, the vehicle may require manual
              intervention to be reactivated.
            </li>
          </ul>
          <div>
            Type 'immobilize' below to confirm your understanding and intent to
            proceed.
          </div>
          <Input
            placeholder="immobilize"
            value={inputValue}
            onChange={handleInputChange}
          />
          <Button
            variant={isInputCorrect ? "destructive" : "disabled"}
            disabled={!isInputCorrect}
            onClick={() => {
              submitSMSImmobilize(selectedVehicle.tracker_id);
              window.location.reload();
            }}
          >
            Immobilize Vehicle
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
